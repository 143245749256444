import React from 'react';
import Layout from '../../layouts/contact/contact';
import Banner from '../../page-components/contact/banner/banner';

const Contact = () => {
  return (
    <Layout>
      <Banner
        who={'Us About Customer Service Chatbots'}
        messages={[
          'Improve chatbot responses',
          'Identify unhandled and mis-handled user Intents',
          'Increase user satisfaction',
          'Reduce escalations and increase containment',
          'Get real-time notifications to insert live agents',
          'Work on human-to-human chat!',
        ]}
      />
    </Layout>
  );
};

export default Contact;
